/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { object, string, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import loadable from '@loadable/component';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import { getCurrentPageView } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import SidebySide4Up from '../SidebySide4Up/SidebySide4Up';

const NUpDesktop = loadable(() => import(/* webpackChunkName: "NUpDesktop" */ './Desktop/NUpDesktop'));
const NUpMobile = loadable(() => import(/* webpackChunkName: "NUpMobile" */ './Mobile/NUpMobile'));
const NupV2 = loadable(() => import(/* webpackChunkName: "NupV2" */ '../NupV2/NupV2'));
const NupV3 = loadable(() => import(/* webpackChunkName: "NupV3" */ '../NupV3/NupV3'));
const NUpV4 = loadable(() => import(/* webpackChunkName: "NupV3" */'../NupV4/NupV4'));
const CalloutPanels = loadable(() => import(/* webpackChunkName: "CalloutPanels" */ './CalloutPanels'));

const useStyles = makeStyles((theme) => ({
    block: ({ stylesObj, omitMarginBottom }) => ({
        position: 'relative',
        margin: (omitMarginBottom) ? '0 auto' : '0 auto 32px', // 32px is default for all homepage blocks.
        [theme.breakpoints.down(1024)]: {
            margin: (omitMarginBottom) ? '0 auto' : '0 auto 16px',
        },
        backgroundColor: stylesObj?.background_color?.color || 'transparent',
        width: '100%',
        display: 'inline-block',
    }),
    blockWithGrid: ({ stylesObj }) => ({
        position: 'relative',
        margin: '0 auto',
        backgroundColor: stylesObj?.background_color?.color || 'transparent',
        width: '100%',
        display: 'inline-block',
    }),
}));

const dataSelector = (data) => (!data?.reference?.[0].reference?.[0] && data ? { reference: [data] } : data);

const NUpRow = ({
    data: blockData,
    headingAlignment,
    headingAlignmentMobile,
    fixedMessage,
    categoryTop,
}) => {
    const positionABTestIndicator = blockData?.controller_for_block_position_ab_tests;

    const dispatch = useDispatch();
    useEffect(() => {
        // adding positionABTestIndicator to condition to control firing of GA event, will fire only alongside rendered NUPs that are part of the test this way
        if (positionABTestIndicator === 'Variant') {
            dispatch(trackEvent({
                eventCategory: 'HP Recs test',
                eventAction: 'Eligible',
                eventLabel: 'Variant',
            }));
        } else if (positionABTestIndicator === 'Control') {
            dispatch(trackEvent({
                eventCategory: 'HP Recs test',
                eventAction: 'Eligible',
                eventLabel: 'Control',
            }));
        }
    }, [positionABTestIndicator]);

    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
        includeRefs: ['reference'],
        dataSelector,
    });

    const ffIsSameDayEnabled = useSelector(getFeatureFlag('is-same-day-collection-v2'));

    const isBot = useSelector(getIsBot);
    const dataUniqueId = uniqueId(data?.reference?.[0]?.unique_selector);
    const cmsuid = data?.reference?.[0]?.uid || null;
    const { display_on = 'Both' } = data?.reference?.[0] || {};
    const isMobile = display_on === 'Mobile' || display_on === 'Both';
    const isDesktop = display_on === 'Desktop' || display_on === 'Both';
    const pageView = useSelector(getCurrentPageView);

    const getNupData = data?.reference?.[0]?.reference?.[0]?.background;
    const omitMarginBottom = data?.reference?.[0]?.omit_bottom_margin;
    const classes = useStyles({
        stylesObj: getNupData,
        omitMarginBottom,
    });

    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const blockCssStyle = ffHasHomepageGridStructure ? classes.blockWithGrid : classes.block;
    // Cannot return null above hooks as we need the same number of hooks on every render
    if (!data) return <></>;

    if (data.reference?.[0]?.reference?.[0]?._content_type_uid === 'nup_v2') {
        if (loading) {
            return (
                <DesktopMobile
                    desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                    mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
                />
            );
        }
        return (
            <div className={`nup_blockV2 ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                <NupV2 data={data.reference[0]} />
            </div>
        );
    }

    if (data.reference?.[0]?.reference?.[0]?._content_type_uid === 'nup_v3') {
        if (loading) {
            return (
                <DesktopMobile
                    desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                    mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
                />
            );
        }
        if (categoryTop && !ffIsSameDayEnabled) {
            return <></>;
        }
        return (
            <div className={`nup_blockV3 ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                <NupV3 data={data.reference[0]} />
            </div>
        );
    }

    if (data.reference?.[0]?.reference?.[0]?._content_type_uid === 'nup_v4') {
        if (loading) {
            return (
                <DesktopMobile
                    desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                    mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
                />
            );
        }
        return (
            <div className={`nup_blockV4 ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                <NUpV4 data={data?.reference[0]} />
            </div>
        );
    }

    // 4431
    if (data.reference?.[0]?.reference?.[0]?._content_type_uid === 'side_by_side_4up') {
        if (loading) {
            return (
                <DesktopMobile
                    desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                    mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
                />
            );
        }
        if (categoryTop && !ffIsSameDayEnabled) {
            return <></>;
        }
        return (
            <div className={`nup_blockV3 ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                <SidebySide4Up data={data.reference[0]} />
            </div>
        );
    }

    if (data?.reference[0]?.unique_selector) {
        if ((data.reference[0]?._content_type_uid === 'n_up')) {
            return (
                <>
                    <DesktopMobile
                        desktop={() => {
                            if (!isDesktop) return null;
                            if ((loading) && blockData?.desktop_skeleton) {
                                return (
                                    <div className={`nup_block ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                                        <GenericSkeleton height={blockData?.skeleton_height} />
                                    </div>
                                );
                            }
                            return (
                                <div className={`nup_block ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                                    <NUpDesktop data={data} headingAlignment={headingAlignment} fixedMessage={fixedMessage} isBot={isBot} />
                                </div>
                            );
                        }}
                        mobile={() => {
                            if (!isMobile) return null;
                            if ((loading) && blockData?.mobile_skeleton) {
                                return (
                                    <div className={`nup_block ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                                        <GenericSkeleton height={blockData?.mobile_skeleton_height || blockData?.skeleton_height} />
                                    </div>
                                );
                            }
                            return (
                                <div className={`nup_block ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                                    <NUpMobile data={data} headingAlignment={headingAlignmentMobile} fixedMessage={fixedMessage} isBot={isBot} pageView={pageView} />
                                </div>
                            );
                        }}
                        breakpoint={700}
                    />

                </>
            );
        }
    }

    if (data.reference?.[0]?.reference?.[0]?._content_type_uid === 'callout_panels') {
        if (loading) {
            return (
                <DesktopMobile
                    desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                    mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
                />
            );
        }
        return (
            <div className={`calloutPanels ${blockCssStyle}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                <CalloutPanels data={data.reference[0]} />
            </div>
        );
    }

    return <></>;
};

NUpRow.defaultProps = {
    headingAlignment: 'center',
    headingAlignmentMobile: 'center',
    fixedMessage: false,
    categoryTop: false,
};

NUpRow.propTypes = {
    data: object.isRequired,
    headingAlignment: string,
    headingAlignmentMobile: string,
    fixedMessage: bool,
    categoryTop: bool,
};

export default (NUpRow);
